<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>MyPrezence</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="2" sm="2" lg="2" xl="2">
        <v-select
          v-model="memberStatus"
          :items="memberStatusItems"
          
          dense
          outlined
          item-text="text"
          item-value="value"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3" sm="3" lg="3" xl="3">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
          <!-- <v-btn @click="exporttoexcel()" small class="gradient-bg white--text">
            <v-icon dense class="mr-2">mdi-export</v-icon>Export
          </v-btn> -->
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table  calculate-widths
           sort-by="name"
          :headers="AppUsersData"
          :options.sync="options" 
          :items="AppUsersItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :loading="isLoading"
          :items-per-page="10"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No App Users available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.user_activated_on`]="{ item }">
            <span>{{
              item.user_activated_on != null
                ? get_date(item.user_activated_on)
                : "N/A"
            }}</span>
          </template>
          <template v-slot:[`item.user_created_on`]="{ item }">
            <span>{{
              item.user_created_on != null
                ? get_date(item.user_created_on)
                : "N/A"
            }}</span>
          </template>
          <template v-slot:[`item.member_app_version`]="{ item }">
            <v-icon
              class="ml-n2 mr-2"
              medium
              :color="
                item.member_device_type == 'ANDROID' ? '#a4c639' : '#555555'
              "
             
            >{{ item.member_device_type == 'ANDROID'
                  ? 'mdi-android'
                  : item.member_device_type == 'IOS'
                  ? 'mdi-apple'
                  : '-' }}</v-icon>
            <span>{{ item.member_app_version }} </span>
          </template>
          <template
            v-for="(h, i) in AppUsersData"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            <div :key="i">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span
                  class="white--text"
                  v-if="header.tooltip_text != undefined"
                  >{{ header.tooltip_text }}</span
                >
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{ item.user_name }}
                </div>
              </template>
              <span class="white--text">{{ item.user_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.organisation_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">
                  {{ item.organisation_name }}
                </div>
              </template>
              <span class="white--text">{{ item.organisation_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.member_app_last_login`]="{ item }">
          <span v-if="item.member_app_last_login != undefined">{{
            get_date2(item.member_app_last_login)
          }}</span>
          <span v-else>-</span>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllMembersByStatus } from "@/graphql/queries.js";
import { parseTime } from "@/utils";

export default {
  components: {
    Overlay,
    SnackBar,
  },
  data: () => ({
    isLoading: false,
    AppUsersData: [
      { 
        text: "Member Name",
         value: "user_name", 
         tooltip_text:"Member Name",
         sortable: false 
      },
      { text: "Member ID", 
      value: "employee_id", 
      tooltip_text: "Member ID", 
      sortable: false },
      {
        text: "Organization Name",
        value: "organisation_name",
        tooltip_text: "Organization Name",
        sortable: false,
      },
      { text: "Activated On", 
      value: "user_activated_on",
      tooltip_text:"Activated On", 
       sortable: false },
      {
        text: "Contact Number",
        value: "user_full_contact_number",
        tooltip_text:"Contact Number",
        sortable: false,
      },
      {
        text: "Last Login",
        value: "member_app_last_login",
        tooltip_text: "Last Login",
        sortable: false,
      },
      {
        text: "DT / AV ",
        value: "member_app_version",
        tooltip_text: "Device Type / App Version",
        sortable: false,
      },
    ],
    AppUsersItems: [],
    search: "",
    options:{},
    fixed: true,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    memberStatus: "ACTIVE",
    memberStatusItems: [
      { text: "Active", value: "ACTIVE" },
      { text: "InActive", value: "DEACTIVE" },
    ],
  }),
  mounted() {
    this.height = window.innerHeight - 210;
    this.memberStatus = "ACTIVE";
    this.get_members_list(this.memberStatus);
  },
  watch: {
    memberStatus(val) {
      this.get_members_list(val);
    },
  },
  methods: {
    get_date2(date) {
      let a = new Date(date).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    exporttoexcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Organization Name",
          "Member Name",
          "Member ID",
          "Contact Number",
          "Device Type",
          "App version",
          "Activated On",
        ];
        const filterVal = [
          "organisation_name",
          "user_name",
          "employee_id",
          "user_full_contact_number",
          "DeviceTypes",
          "Appversions",
          "ActivatedOns",
        ];
        var self = this;
        const list = this.AppUsersItems;
        list.forEach(function (findx) {
          findx.ActivatedOns =
            findx.user_activated_on != null
              ? `${self.get_date(findx.user_activated_on)}`
              : "-";
          findx.InvitedOns =
            findx.user_created_on != null
              ? `${self.get_date(findx.user_created_on)}`
              : "-";
          findx.DeviceTypes =
            findx.member_device_type != null ? findx.member_device_type : "-";
          findx.Appversions =
            findx.member_app_version != null ? findx.member_app_version : "-";
        });
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "MyPrezence Users List",
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_members_list(status) {
      this.AppUsersItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllMembersByStatus, {
            input: {
              user_status: status,
              app_type: "MYPRZNCE",
            },
          })
        );
        this.AppUsersItems = JSON.parse(result.data.GetAllMembersByStatus)
          .filter(
            (item) =>
              item.user_activated_on != null ||
              item.user_activated_on != undefined
          )
          .sort((a, b) => (a.user_activated_on < b.user_activated_on ? 1 : -1));
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
